import {memo} from "react";
import {Tooltip, Zoom} from "@mui/material";
import "./index.scss";


const CustomTooltip = props => (
    <Tooltip
        {...props}
        classes={{popper: 'custom-tooltip'}}
        TransitionComponent={Zoom}
    />
);

export default memo(CustomTooltip);