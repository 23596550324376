import {memo, useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AuthContext} from "../../../../context/AuthContext";
import {useRequest} from "../../../../hooks/request.hook";
import "./index.scss";

// const stageWindow = window.open('https://stage.uep24.ru/', 'stageWindow', 'width=300,height=300,left=800,top=300');
//
// stageWindow.onload = () => {
//     console.log(stageWindow.location);
// }

const UserLogin = () => {
    const {isAuthenticated, login, logout} = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const {request} = useRequest();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const apikey = searchParams.get('t');
        const email = searchParams.get('e');
        const name = searchParams.get('n');

        if(apikey && email && name) {
            if(!isAuthenticated) {
                request({
                    url: `/api/v1/wiki/user`,
                    method: 'POST',
                    data: {
                        email,
                        apikey
                    }
                }).then(data => {
                    if(data?.length) {
                        login({
                            api_key: data[0].token,
                            user: {
                                id: data[0].id,
                                email: data[0].email,
                                name: data[0].organization[0].name
                            }
                        });
                    }
                });
            }

            searchParams.delete('t');
            searchParams.delete('e');
            searchParams.delete('n');

            navigate(location.pathname + searchParams.toString() ? '?' + searchParams.toString() : '', {replace: true});
        }
    }, [location]);

    return !isAuthenticated ?
        <a
            href={`https://rkf.online?redirect_url=${window.location.href}`}
            className="header__link"
        >
            Войти
        </a> :
        <span className="header__link" onClick={logout}>Выйти</span>
};

export default memo(UserLogin);