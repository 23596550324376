import {memo} from "react";
import {Button} from "@mui/material";
import "./index.scss";


const CustomButton = ({className, bgColor, type = 'button', disabled = false, onClick, children}) => (
    <Button
        className={`custom-button${bgColor ? ' _' + bgColor : ''}${className ? ' ' + className : ''}`}
        type={type}
        disabled={disabled}
        onClick={onClick ? onClick : () => null}
    >
        {children}
    </Button>
);

export default memo(CustomButton);