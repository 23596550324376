import {memo, useContext} from "react";
import StickyBox from "react-sticky-box";
import {Link} from "react-router-dom";
import Loading from "../../Loading";
import MenuLink from "./MenuLink";
import {MenuContext} from "../../../context/MenuContext";
import logo from "../../../assets/images/logo.svg";
import "./index.scss";


const MenuSidebar = ({closeMenu}) => {
    const {loading, menuCategories, activeCategory, activeParentSubCategory,activeParentCategory} = useContext(MenuContext);

    return (
        <div className="sidebar">
            <StickyBox className="menu-sidebar" offsetTop={50}>
                <div className="menu-sidebar__top">
                    <Link to="/" className="menu-sidebar__logo">
                        <img src={logo} alt=""/>
                        {/*<p>Центр поддержки</p>*/}
                    </Link>
                    <h5 className="menu-sidebar__title">Категории</h5>
                </div>
                {loading ?
                    <Loading fixed={false}/> :
                    <ul className="menu-sidebar__list">
                        {menuCategories.map(category =>
                            <li className="menu-sidebar__item" key={category.id}>
                                <MenuLink
                                    category={category}
                                    isActive={activeParentCategory ?
                                        activeParentCategory.id === category.id :
                                        activeCategory?.id === category.id
                                    }
                                    activeParentSubCategory={activeParentSubCategory}
                                    closeMenu={closeMenu}
                                />
                            </li>
                        )}
                    </ul>
                }
            </StickyBox>
        </div>
    );
};

export default memo(MenuSidebar);