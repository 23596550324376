import {
    memo, 
    Suspense
} from "react";
import {
    BrowserRouter, 
   Routes, 
   Route, 
   Navigate
} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import Loading from "./components/Loading";
import Layout from "./components/Layout";
import {routes} from "./pages/Routes";
import {useAuth} from "./hooks/auth.hook";
import {AuthContext} from "./context/AuthContext";
import { BubbleChat } from 'flowise-embed-react';

const App = () => {
    const {token, user, ready, login, logout} = useAuth();
    const isAuthenticated = !!token && !!user;

    return !ready ?
        <Loading/> :
        <SnackbarProvider
            maxSnack={7}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
        >
            <BrowserRouter>
                <AuthContext.Provider value={{token, user, isAuthenticated, login, logout}}>
                    <Routes>
                         {routes.map(route =>
                            <Route
                                key={route.path}
                                exact={route.exact}
                                path={route.path}
                                element={
                                    <Layout withMenu={route.with_menu}>
                                        <Suspense fallback={<Loading/>}>
                                            <route.element/>
                                        </Suspense>
                                    </Layout>
                                }
                            />
                        )}
                        <Route
                            path="*"
                            element={<Navigate to="/" replace />}
                        />
                    </Routes>
                </AuthContext.Provider>
            </BrowserRouter>
            <BubbleChat 
                chatflowid="381aa8a2-3bf2-4677-bc8d-caa472005a76"
                apiHost="https://flowise.ai.dfv24.com"
                theme={{
                    button: {
                        backgroundColor: "#369D8C",
                        iconColor: "white",
                    },
                    chatWindow: {                        
                        welcomeMessage: "Здравствуйте! Мы рады ответить на Все Ваши вопросы.",
                        userMessage: {
                            backgroundColor: "#E5F5F3",
                            textColor: "black",
                        },
                        textInput: {
                            placeholder: "Напишите Ваш вопрос...",
                        }
                    }
                }}                    
            />
        </SnackbarProvider>
};

export default memo(App);