import {memo} from "react";
import CustomTooltip from "../../CustomTooltip";
import {VKIcon} from "../../../assets/icons/VKIcon";
import {TelegramIcon} from "../../../assets/icons/TelegramIcon";
import {YoutubeIcon} from "../../../assets/icons/YoutubeIcon";
import {RutubeIcon} from "../../../assets/icons/RutubeIcon";
import {DzenIcon2} from "../../../assets/icons/DzenIcon2";
import {useIsMobile} from "../../../hooks/isMobile.hook";
import "./index.scss";


const Footer = () => {
    const isMobile = useIsMobile(1085)

    return (
        <footer className="footer">
            <div className="footer__wrap content">
                <p className="footer__text">
                    <a href="mailto:rkf@rkf.org.ru" className="footer__text-link">rkf@rkf.org.ru</a><br/>
                    <span>Москва, ул. Гостиничная, д.9</span><br/>
                    <a
                        href="http://rkf.org.ru/zapis-na-poseshhenie-ofisa-rkf/"
                        className="footer__text-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Запись на посещение офиса РКФ и федераций
                    </a>
                </p>
                <div className="footer__text">
                    <ul className="footer__socials-list">
                        <li className="footer__socials-item">
                            <CustomTooltip title="VK">
                                <a href="https://vk.com/ruskynologfed" target="_blank" rel="noreferrer">
                                    <VKIcon viewBox="0 0 32 19"/>
                                </a>
                            </CustomTooltip>
                        </li>
                        <li className="footer__socials-item">
                            <CustomTooltip title="Telegram">
                                <a href="https://t.me/russiankinologfed" target="_blank" rel="noreferrer">
                                    <TelegramIcon viewBox="0 0 22 19"/>
                                </a>
                            </CustomTooltip>
                        </li>
                        <li className="footer__socials-item">
                            <CustomTooltip title="Youtube">
                                <a href="https://www.youtube.com/channel/UC1mzNt3TccDxGfA-vkEAQig" target="_blank" rel="noreferrer">
                                    <YoutubeIcon viewBox="0 0 26 18"/>
                                </a>
                            </CustomTooltip>
                        </li>
                        <li className="footer__socials-item">
                            <CustomTooltip title="Rutube">
                                <a href="https://rutube.ru/channel/24811120/" target="_blank" rel="noreferrer">
                                    <RutubeIcon viewBox="0 0 17 18"/>
                                </a>
                            </CustomTooltip>
                        </li>
                        <li className="footer__socials-item">
                            <CustomTooltip title="Дзен">
                                <a href="https://dzen.ru/rkf_org" target="_blank" rel="noreferrer">
                                    <DzenIcon2 viewBox="0 0 18 18"/>
                                </a>
                            </CustomTooltip>
                        </li>
                    </ul>
                    {!isMobile &&
                        <p>
                            <a
                                href="http://rkf.org.ru/Politika_PDn.pdf"
                                className="footer__text-link"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Соглашение о пользовании системой
                            </a><br/>
                            <span>&copy; 1991—{new Date().getFullYear()}. СОКО РКФ</span>
                        </p>
                    }
                </div>
            </div>
        </footer>
    );
};

export default memo(Footer);