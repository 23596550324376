import {memo, useContext, useState} from "react";
import {Link} from "react-router-dom";
import CustomTooltip from "../../CustomTooltip";
// import LanguageSwitcher from "./LanguageSwitcher";
import UserLogin from "./UserLogin";
import {Icon, IconButton, SwipeableDrawer} from "@mui/material";
import MenuSidebar from "../MenuSidebar";
import CustomModal from "../../CustomModal";
import CustomButton from "../../CustomButton";
import {useIsMobile} from "../../../hooks/isMobile.hook";
import {AuthContext} from "../../../context/AuthContext";
import "./index.scss";


const servers = () => [
    {
        name: 'RKF.Online',
        description: 'Платформа для кинологов',
        link: 'https://rkf.online/',
        is_active: true
    },
    {
        name: 'RKF.org.ru',
        description: 'Российская Кинологическая Федерация',
        link: 'http://rkf.org.ru/',
        is_active: true
    },
    {
        name: 'RKF.Academy',
        description: 'Образовательный центр РКФ',
        link: 'https://rkf.academy/',
        is_active: true
    },
    {
        name: 'RKF.Show',
        description: 'Выставочный портал РКФ',
        link: 'http://lc.rkfshow.ru/',
        is_active: true
    },
    {
        name: 'EVC.ru',
        description: 'Ветеринария',
        link: 'https://evc.ru/',
        is_active: true
    }
];

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const {isAuthenticated} = useContext(AuthContext);
    const isMobile = useIsMobile(1085);

    return (
        <header className="header">
            <div className="header__wrap content">
                {isMobile &&
                    <IconButton
                        className="header__menu-btn"
                        onClick={() => setIsMenuOpen(true)}
                    >
                        <Icon>menu</Icon>
                    </IconButton>
                }
                {!isMobile &&
                    <ul className="header__links-list">
                        {servers().map(server =>
                            <li className="header__links-item" key={server.name}>
                                <CustomTooltip title={server.is_active ? 'Сервер работает' :  'Сервер не работает'}>
                                    <span className={`header__links-indicator${server.is_active ? ' _active' : ''}`}/>
                                </CustomTooltip>
                                <CustomTooltip title={server.description}>
                                    <a
                                        href={server.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="header__link"
                                    >
                                        {server.name}
                                    </a>
                                </CustomTooltip>
                            </li>
                        )}
                    </ul>
                }
                {isAuthenticated ?
                    <Link to="/my_questions" className="header__link">Мои обращения</Link> :
                    <span
                        className="header__link"
                        onClick={() => setIsOpenModal(true)}
                    >
                        Мои обращения
                    </span>
                }
                <UserLogin />
            </div>
            {isMobile &&
                <SwipeableDrawer
                    className="header__menu"
                    open={isMenuOpen}
                    onOpen={() => setIsMenuOpen(true)}
                    onClose={() => setIsMenuOpen(false)}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <div className="header__menu-control">
                        <IconButton onClick={() => setIsMenuOpen(false)}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                    <MenuSidebar closeMenu={() => setIsMenuOpen(false)}/>
                </SwipeableDrawer>
            }
            <CustomModal
                isOpen={isOpenModal}
                title="Мои обращения"
                onClose={() => setIsOpenModal(false)}
            >
                <p className="header__modal-text">
                    Чтобы посмотреть заявки, Вам необходимо быть авторизованным на платформе <a href={`https://rkf.online?redirect_url=${window.location.origin + '/my_questions'}`}>rkf.online</a>
                </p>
                <CustomButton
                    className="header__modal-button"
                    onClick={() => window.location.replace(`https://rkf.online?redirect_url=${window.location.origin + '/my_questions'}`)}
                >
                    Вход
                </CustomButton>
            </CustomModal>
        </header>
    );
};

export default memo(Header);