import {useCallback, useEffect, useState} from "react";


export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [ready, setReady] = useState(false);

    const login = useCallback(data => {
        localStorage.setItem('api_key', JSON.stringify(data.api_key));
        localStorage.setItem('user', JSON.stringify(data.user));

        setToken(data.api_key);
        setUser(data.user);
    }, []);
 
    const logout = useCallback(() => {
        localStorage.removeItem('api_key');
        localStorage.removeItem('user');

        setToken(null);
        setUser(null);
    }, []);

    useEffect(() => {
        const localToken = JSON.parse(localStorage.getItem('api_key') || "null");
        const localUser = JSON.parse(localStorage.getItem('user') || "null");

        if(localToken && localUser) {
            const data = {
                api_key: localToken,
                user: localUser
            }

            login(data);
        }

        setReady(true);
    }, [login]);

    return {user, token, login, logout, ready};
};