import {memo} from "react";
import {CircularProgress} from "@mui/material";
import "./index.scss";


const Loading = ({fixed = true}) => (
    <div className={`loading${fixed ? ' _fixed' : ''}`}>
        <CircularProgress />
    </div>
);

export default memo(Loading);