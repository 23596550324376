import {memo, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {Accordion, AccordionSummary, AccordionDetails} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";


const MenuLink = ({category, isActive, closeMenu, activeParentSubCategory}) => {
    const [isOpen, setIsOpen] = useState(isActive);

    useEffect(() => {
        setIsOpen(isActive);
    }, [isActive]);

    return !category.sub_menu.length ?
        <NavLink to={`/cat/${category.id}`} className="menu-link">{category.title}</NavLink> :
        <Accordion
            className={`menu-link__accordion${isActive ? ' _active' : ''}`}
            expanded={isOpen}
            onChange={() => setIsOpen(prev => !prev)}
        >
            <AccordionSummary
                className="menu-link__accordion-top"
                expandIcon={<ExpandMore />}
            >
                <NavLink
                    to={`/cat/${category.id}`}
                    className="menu-link"
                    onClick={e => {
                        e.stopPropagation();
                        setIsOpen(true);
                        if(closeMenu) {
                            closeMenu();
                        }
                    }}
                >
                    {category.title}
                </NavLink>
            </AccordionSummary>
            <AccordionDetails className="menu-link__accordion-body">
                <ul className="menu-link__list">
                    {category.sub_menu.map(subcategory =>
                        <li className="menu-link__item" key={subcategory.id}>
                            {!subcategory.sub_menu?.length ?
                                <NavLink
                                    to={`/cat/${subcategory.id}`}
                                    className="menu-link"
                                    onClick={() => {
                                        if(closeMenu) {
                                            closeMenu();
                                        }
                                    }}
                                >
                                    {subcategory.title}
                                </NavLink>
                                :
                                <MenuLink
                                    category={subcategory}
                                    isActive={activeParentSubCategory?.id === subcategory.id}
                                    closeMenu={closeMenu}
                                    activeParentSubCategory={null}
                                />
                            }
                        </li>
                    )}
                </ul>
            </AccordionDetails>
        </Accordion>
};

export default memo(MenuLink);